import { useRouter } from 'next/router';
import Image from 'next/image';
import { FC } from 'react';
import styles from './JobPopup.module.scss';

interface JobPopupProps {
  onClose: any;
  onNavigate: any;
}

const JobPopup: FC<JobPopupProps> = ({ onClose, onNavigate }) => {
  const router = useRouter();

  return (
    <div className={styles.modal} onClick={onClose}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={styles.content}
      >
        <div className={styles.wrapper}>
          <div className={styles.overlay}>
            <div className={styles.close} onClick={onClose} />
            <h6 className={styles.title}>JOIN THE WILLIS CUSTOM YACHTS TEAM!</h6>
            <button
              onClick={() => {
                router.push('/job-openings');
                onNavigate();
              }}
            >
              Apply Now
            </button>
          </div>
          <Image className={`${styles.image}`} src="/images/job-posting-popup-bg.jpg" alt="Job Opening" layout="fill" />
        </div>
      </div>
    </div>
  );
};

export default JobPopup;
