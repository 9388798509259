/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @next/next/link-passhref */
import React, { FC, useState } from 'react';

import styles from './Navbar.module.scss';
import LogoWhite from '../../public/images/logo-white.svg';
import Image from 'next/image';
import { HiOutlineMenuAlt3 } from 'react-icons/hi';
import { AiOutlineClose } from 'react-icons/ai';
import Drawer from '../Drawer';
import { ContentDataType, MenuItemDataType } from 'models/types.interface';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import Menubar from 'animations/Menubar';

interface NavbarProps {
  menuItems: MenuItemDataType[];
  footer: ContentDataType;
}

const Navbar: FC<NavbarProps> = ({ menuItems, footer }) => {
  const [open, setOpen] = useState(null as any);
  const [animating, setAnimating] = useState(false);
  const router = useRouter();
  const menubarAnimation = new Menubar();

  const toggleOpen = () => {
    if (animating) return;
    setOpen(!open);
  };

  useEffect(() => {
    menubarAnimation.init();

    if (open !== null) {
      setAnimating(true);
      if (open) {
        setAnimating(true);
        menubarAnimation.open(() => setAnimating(false));
      } else {
        menubarAnimation.close(() => setAnimating(false));
      }
    }
  }, [open]);

  return (
    <>
      <Drawer footer={footer} menuItems={menuItems} open={open} toggleOpen={toggleOpen} />
      <nav className={`${styles.navbar} navbar-main`}>
        <div className={styles.wrapper}>
          <div className={`${styles.img} box-fade-in`}>
            <Image onClick={() => router.push('/')} src={LogoWhite} />
          </div>

          <div onClick={toggleOpen} className={`${styles.menu} menubar box-fade-in`}>
            <div className={`${styles.option} menubar-item menubar-item--1`}>
              <span className="menubar-item-el">Menu</span>
              <HiOutlineMenuAlt3 className="menubar-item-el" />
            </div>
            <div className={`${styles.option} menubar-item menubar-item--2 box-fade-in`}>
              <span className="menubar-item-el">Close</span>
              <AiOutlineClose className="menubar-item-el" />
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
