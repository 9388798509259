const loadImages = (paths: any[], whenLoaded: any, progress?: any) => {
  const imgs: HTMLImageElement[] = [];

  if (paths.length === 0) {
    whenLoaded();
  }

  paths.forEach(function (path) {
    const img = new Image();
    img.onload = function () {
      imgs.push(img);
      if (progress) progress(imgs.length);
      if (imgs.length == paths.length) whenLoaded(imgs);
    };
    img.src = path;
  });
};

export default loadImages;
