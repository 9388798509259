/* eslint-disable @typescript-eslint/ban-ts-comment */
import PageConfig from 'config/PageConfig';
import ScrollRefContext from 'context/ScrollRefContext';
import VideoContext from 'context/VideoContext';
import useSmoothAnimation from 'hooks/useSmoothAnimations';
import {
  ContentDataType,
  MenuItemDataType,
  PageContentType,
  SeoContentType,
  SocialsContentType,
} from 'models/types.interface';
import React, { FC, useEffect, useState } from 'react';
import Contact from '../Contact';
import Footer from '../Footer';
import Navbar from '../Navbar';
import PreLoader from '../PreLoader';
import SEO from '../SEO';
import VideoModal from '../VideoModal';

interface DataType {
  menuItems: MenuItemDataType[];
  footer: ContentDataType;
  socials: SocialsContentType[];
  seo: SeoContentType;
  data: PageContentType;
  hideContactForm?: boolean;
}

const Layout: FC<DataType> = ({ children, menuItems, footer, socials, seo, data, hideContactForm }) => {
  PageConfig.init();
  const [video, setVideo] = useState(null as any);
  const [init, setInit] = useState(false);
  const { scrollBarRef } = useSmoothAnimation({ loaded: init });
  const initLuckyOrangeTracking = () => {
    //@ts-ignore
    window.__lo_site_id = 328305;

    const wa = document.createElement('script');
    wa.type = 'text/javascript';
    wa.src = 'https://d10lpsik1i8c69.cloudfront.net/w.js';
    wa.async = true;
    const s = document.getElementsByTagName('script')?.[0];
    s?.parentNode?.insertBefore(wa, s);
  };

  useEffect(() => {
    initLuckyOrangeTracking();
  }, []);

  return (
    <>
      <SEO data={seo} />
      <ScrollRefContext.Provider value={scrollBarRef}>
        <VideoContext.Provider value={{ video, setVideo }}>
          <PreLoader onFinish={() => setInit(true)} data={data}>
            <VideoModal />
            <Navbar footer={footer} menuItems={menuItems} />
            <div className="scroll-content">
              <main>{children}</main>
              {!hideContactForm && <Contact scrollBarRef={scrollBarRef} data={footer} menuItems={menuItems} />}
              <Footer title={seo?.opengraphSiteName} data={socials} />
            </div>
          </PreLoader>
        </VideoContext.Provider>
      </ScrollRefContext.Provider>
    </>
  );
};

export default Layout;
