import React, { FC, useContext } from 'react';
import Button from '@/components/Button';

import { HeroContentType } from 'models/types.interface';

import styles from './Hero.module.scss';
import BulletList from '../BulletList';
import ImageList from '../ImageList';
import VideoContext from 'context/VideoContext';

export interface HeroProps {
  data: HeroContentType;
  showImageList?: boolean;
  imagePositionTop?: boolean;
}

const Hero: FC<HeroProps> = ({ data, showImageList, imagePositionTop }) => {
  const isImageList = !!(data?.list && data.image);
  const isDrawerList = !!data?.list && !data.image;
  const { setVideo }: any = useContext(VideoContext);

  return (
    <div className={styles.hero}>
      <div className={styles.main}>
        <div className={styles.overlay}>
          <h1 className={`${styles.title} line-fade-in`}>{data?.headline}</h1>
          <h2 className={styles.sub_title}>{data?.subHeadline}</h2>
          {data?.textButton && (
            <Button onClick={() => setVideo(data?.linkButton)} type="hero">
              {data?.textButton}
            </Button>
          )}
        </div>
        {data?.background?.mediaItemUrl ? (
          <video autoPlay muted loop className={styles.video} src={data?.background?.mediaItemUrl} />
        ) : data?.background?.sourceUrl ? (
          <img
            className={`${styles.image} ${imagePositionTop ? styles?.top : ''}`}
            src={data?.background?.sourceUrl ?? ''}
            alt={data?.background?.altText}
          />
        ) : (
          <div className={`${styles.image}`}></div>
        )}
      </div>
      {isImageList && showImageList && <ImageList image={data?.image} list={data?.list} />}

      {data?.description && (
        <div className={`${styles.desc} line-fade-in`}>
          <div dangerouslySetInnerHTML={{ __html: data?.description }}></div>
        </div>
      )}

      {isDrawerList && <BulletList noMarginBottom={!!data?.secondDescription} data={data?.list ?? []} />}
      {data?.secondDescription && (
        <div className={`${styles.desc} line-fade-in`}>
          <div dangerouslySetInnerHTML={{ __html: data?.secondDescription ?? '' }}></div>
        </div>
      )}
    </div>
  );
};

export default Hero;
