import React, { FC } from 'react';

import styles from './Button.module.scss';

interface ButtonProps {
  type?: 'hero' | 'action' | 'primary';
  href?: string;
  onClick?: any;
  disabled?: boolean;
}

const Button: FC<ButtonProps> = ({ children, type = 'action', href, onClick, disabled }) => {
  return (
    <button
      disabled={disabled}
      onClick={() => {
        if (href) {
          window.open(href, '_self');
        } else if (onClick) {
          onClick();
        }
      }}
      className={`${styles.button} box-fade-in ${
        type === 'action' ? styles.action : type === 'primary' ? styles.primary : styles.hero
      }`}
    >
      {children}
    </button>
  );
};

export default Button;
