export default {
  originalTitle: 'WordPress Website',
  defaultURL: 'https://www.sitename.com',
  originalImage: 'https://plchldr.co/i/500x250',
  author: {
    name: 'author',
  },
  originalDescription: 'The Best WordPress Website',
  social: {
    twitter: 'Custom',
  },
  siteName: 'Website',
};
