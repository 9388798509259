import VideoContext from 'context/VideoContext';
import React, { FC, useContext, useEffect } from 'react';
import { isSafari } from 'utils/string.utils';
import styles from './VideoModal.module.scss';

interface VideoModalProps {}

const VideoModal: FC<VideoModalProps> = () => {
  const { video, setVideo }: any = useContext(VideoContext);

  useEffect(() => {
    if (video) {
      const videoRef: HTMLVideoElement | null = document.querySelector('.video-modal');
      if (videoRef) {
        videoRef.muted = isSafari();
      }
    }
  }, [video]);

  return (
    <div onClick={() => setVideo(null)} className={`${styles.videoModal} ${!video ? styles.hidden : ''}`}>
      {video && (
        <video
          className="video-modal"
          onClick={(e) => {
            e.stopPropagation();
          }}
          autoPlay
          muted
          controls
          src={`${video}?ngsw-bypass=true`}
        />
      )}
    </div>
  );
};

export default VideoModal;
