import gsap from 'gsap';

export default class Menubar {
  menubar?: HTMLElement | null;
  menubarItem1?: HTMLElement | null;
  menubarItem2?: HTMLElement | null;
  menubar1Els: NodeListOf<Element> | null | undefined;
  menubar2Els: NodeListOf<Element> | null | undefined;
  drawer?: HTMLElement | null;
  drawerLinks: NodeListOf<HTMLLIElement> | undefined;

  init() {
    if (this.menubar) return;

    this.menubar = document.querySelector('.menubar');
    this.menubarItem1 = document.querySelector('.menubar-item--1');
    this.menubarItem2 = document.querySelector('.menubar-item--2');
    this.menubar1Els = this.menubarItem1?.querySelectorAll('.menubar-item-el');
    this.menubar2Els = this.menubarItem2?.querySelectorAll('.menubar-item-el');
    this.drawer = document.querySelector('.drawer');
    this.drawerLinks = this.drawer?.querySelectorAll('li');
  }

  open(onFinish?: any) {
    if (this.menubarItem2 && this.menubarItem1 && this.drawer && this.drawerLinks) {
      gsap.set(this.menubarItem2, { y: '-200%' });
      gsap.set(this.drawerLinks, { opacity: 1, y: 0 });

      const tl = gsap.timeline();

      tl.to(this.menubarItem2, { y: '-50%', stagger: 0.2, direction: 1 }, 0)
        .to(this.menubarItem1, { y: '200%', stagger: 0.2, direction: 1 }, 0)
        .to(
          this.drawer,
          {
            x: 0,
            ease: 'expo.out',
            duration: 1,
          },
          0
        )
        .from(
          this.drawerLinks,
          {
            y: 100,
            stagger: 0.05,
            opacity: 0,
          },
          0.05
        )
        .then(() => {
          if (onFinish) {
            onFinish();
          }
        });
    }
  }

  close(onFinish?: any) {
    if (this.menubarItem2 && this.menubarItem1 && this.drawer && this.drawerLinks) {
      gsap.set(this.menubarItem1, { y: '-100%' });

      const tl = gsap.timeline();
      tl.to(this.menubarItem1, { y: '0', stagger: 0.2 }, 0)
        .to(this.menubarItem2, { y: '200%', stagger: 0.2 }, 0)
        .to(
          this.drawer,
          {
            x: '100%',
            ease: 'expo.in',
            duration: 0.5,
          },
          0
        )
        .then(() => {
          if (onFinish) {
            onFinish();
          }
        });
    }
  }
}
