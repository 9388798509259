/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import styles from './PreLoader.module.scss';
import LogoWhite from '../../public/images/logo-white.svg';
import Image from 'next/image';
import FontFaceObserver from 'fontfaceobserver';
import { PageContentType } from 'models/types.interface';
import loadImages from 'utils/loadImages';
import gsap from 'gsap';
import JobPopup from '../JobPopup';
import PopupProvider from 'context/PopupProvider';

interface PreLoaderProps {
  data: PageContentType;
  onFinish: any;
}

const PreLoader: React.FC<PreLoaderProps> = ({ children, data, onFinish }) => {
  const [loaded, setLoaded] = React.useState(false);
  const [popup, setPopup] = React.useState(false);
  const { showAd, setShowAd }: any = React.useContext(PopupProvider);

  React.useEffect(() => {
    let imgList = [];

    imgList.push(data?.callAction?.background?.sourceUrl);
    imgList.push(data?.gridImagem?.featuredImage?.sourceUrl);
    imgList.push(data?.gridImagem?.firstImage?.sourceUrl);
    imgList.push(data?.gridImagem?.secondImage?.sourceUrl);
    imgList.push(data?.gridImagem?.thirdImage?.sourceUrl);
    imgList.push(data?.gridImagem?.fourthImage?.sourceUrl);
    imgList.push(data?.header?.background?.sourceUrl);
    imgList.push(data?.joinTeam?.background?.sourceUrl);
    imgList.push(data?.header?.image?.sourceUrl);

    imgList = imgList.filter((el) => !!el);

    const overlayPath = document.querySelector('.overlay__path');
    gsap.set(overlayPath, {
      attr: { d: 'M 0 0 V 100 Q 50 100 100 100 V 0 z' },
    });

    loadImages(
      imgList,
      () => {
        const fontA = new FontFaceObserver('Kimberley Bl');
        const fontB = new FontFaceObserver('Montserrat');

        Promise.all([fontA.load(), fontB.load()]).then(function () {
          setLoaded(true);
          setTimeout(onFinish, 1000);
        });

        gsap.set('.preloader-overlay', {
          backgroundColor: 'transparent',
        });

        gsap.to('.preloader-display', {
          y: '-100%',
          ease: 'power2.in',
          opacity: 0,
          delay: 0.8,
          duration: 0.5,
        });

        const tl = gsap.timeline({ delay: 1 });
        tl.to(overlayPath, {
          duration: 0.3,
          ease: 'power2.in',
          attr: { d: 'M 0 0 V 50 Q 50 0 100 50 V 0 z' },
        }).to(overlayPath, {
          duration: 0.8,
          ease: 'power4',
          attr: { d: 'M 0 0 V 0 Q 50 0 100 0 V 0 z' },
        });
      },
      (c: any) => {
        gsap.to('.preload-progress-status', {
          width: `${(c / imgList.length) * 100}%`,
        });
      }
    );
  }, []);

  // Shows the popup using 24h cookie
  // React.useEffect(() => {
  //   const showPopup = !getCookie('showPopup');

  //   if (loaded && showPopup) {
  //     setTimeout(() => {
  //       setPopup(true);
  //       setCookie('showPopup', 'never-display', 1);
  //     }, 10000);
  //   }
  // }, [loaded]);

  React.useEffect(() => {
    if (loaded && showAd) {
      setTimeout(() => {
        setPopup(true);
        setShowAd(false);
      }, 10000);
    }
  }, [loaded]);

  return (
    <>
      <div style={{ display: popup ? undefined : 'none' }}>
        <JobPopup
          onNavigate={() => {
            setPopup(false);
          }}
          onClose={() => {
            setPopup(false);
          }}
        />
      </div>
      <div className={styles.preLoader}>
        <div className={`${styles.overlay} preloader-overlay`}>
          <div className={`${styles.display} preloader-display`}>
            <div className={styles.logo}>
              <Image src={LogoWhite} />
            </div>
            <div className={styles.progress}>
              <div className={`${styles.status} preload-progress-status`} />
            </div>
          </div>
        </div>
        <div key={loaded ? '--1' : '--non'}>{children}</div>
      </div>
    </>
  );
};

export default PreLoader;
