import React, { FC } from 'react';
import styles from './Dropdown.module.scss';
import Caret from '../../public/images/caret-down.png';

interface DropdownProps {
  label?: string;
  className?: string;
  options?: any[];
  name?: string;
  value?: string;
  onChange?: any;
  error?: any;
  showValue?: boolean;
}

const Dropdown: FC<DropdownProps> = ({ label, className, options = [], value, showValue, name, onChange, error }) => {
  return (
    <div className={`${styles.dropdown} ${className}`}>
      <>
        <label className={styles.label} htmlFor="">
          {label}
        </label>
        <select
          onChange={(e) => {
            if (onChange)
              onChange({
                target: {
                  value: showValue
                    ? e.target.options[e.target.selectedIndex].value
                    : e.target.options[e.target.selectedIndex].text,
                  name,
                },
              });
          }}
          value={value}
          className={styles.head}
          name={name}
        >
          {options?.map((el, i) => {
            return (
              <option key={i} value={el?.value}>
                {el?.label}
              </option>
            );
          })}
        </select>
        <img className={styles.icon} style={{ width: 12, zIndex: 20 }} src={Caret.src} alt="" />

        {error && name && error[name] && <div className={styles.error}>{error[name]}</div>}
      </>
    </div>
  );
};

export default Dropdown;
