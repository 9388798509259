import gsap from 'gsap';
import { isTouchDevice } from './string.utils';

const scrollToEl = async (scrollBarRef: any, className: string) => {
  await new Promise((r) => setTimeout(r, 200));
  if (isTouchDevice()) {
    gsap.to(window, { scrollTo: { y: `.${className}`, offsetY: 100 }, duration: 1 });
  } else {
    scrollBarRef?.scrollIntoView(document.querySelector(`.${className}`) as any, {
      offsetTop: 100,
    });
  }
};

export default scrollToEl;
