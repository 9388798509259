/* eslint-disable prefer-const */
import React, { FC } from 'react';
import Image from 'next/image';

import LogoBlue from '../../public/images/logo-blue.svg';
import styles from './Contact.module.scss';
import Link from 'next/link';
import Input from '../Input';
import Button from '../Button';
import Dropdown from '../Dropdown';
import { ContentDataType, MenuItemDataType } from 'models/types.interface';
import { useRouter } from 'next/router';
import { validateEmail, validatePhone } from 'utils/string.utils';
import apiRoutes from 'config/apiRoutes';
import axios from 'axios';
import Scrollbar from 'smooth-scrollbar';
import scrollToEl from 'utils/scrollToEl';

interface ContactProps {
  menuItems: MenuItemDataType[];
  data: ContentDataType;
  scrollBarRef: null | Scrollbar;
}

const Contact: FC<ContactProps> = ({ menuItems, data, scrollBarRef }) => {
  const router = useRouter();

  const initState = {
    department: 'Service',
  };

  const [state, setState] = React.useState(initState as any);
  const [error, setError] = React.useState(null as any);
  const [loading, setLoading] = React.useState(false);

  const requiredFields = [
    { name: 'first-name', class: 'form-first-name' },
    { name: 'last-name', class: 'form-last-name' },
    { name: 'message', class: 'form-message' },
    { name: 'department', class: 'form-department' },
    { name: 'phone', type: 'phone', class: 'form-phone' },
    { name: 'email', type: 'email', class: 'form-email' },
  ];
  const handleChange = (e: any) => {
    let { name, value } = e.target;
    value = value === 'Yes' ? true : value === 'No' ? false : value;

    setState((prevState: any) => ({ ...prevState, [name]: value }));
  };

  const validateFields = async () => {
    if (loading) return;

    const err = {} as any;
    let hasScrolled = false;
    requiredFields.forEach((el: any) => {
      const value = state[el.name];
      if (
        value !== true &&
        value !== false &&
        !value &&
        !(typeof value === 'string' ? value?.trim() : value === 0) &&
        !el?.notRequired
      ) {
        err[el?.name] = '*This field is required';

        if (!hasScrolled) {
          scrollToEl(scrollBarRef, el.class);
          hasScrolled = true;
        }
      }

      if (el?.type === 'email' && value) {
        if (!validateEmail(value)) {
          err[el?.name] = 'Please enter a valid email.';
          if (!hasScrolled) {
            scrollToEl(scrollBarRef, el.class);
            hasScrolled = true;
          }
        }
      }
      if (el?.type === 'phone' && value) {
        if (!validatePhone(value)) {
          err[el?.name] = 'Please enter a valid phone number.';
          if (!hasScrolled) {
            scrollToEl(scrollBarRef, el.class);
            hasScrolled = true;
          }
        }
      }
    });
    setError(err);
    const errKeys = Object.keys(err);
    if (errKeys.length === 0) {
      try {
        setLoading(true);
        const form = new FormData();
        Object.keys(state).forEach((i) => {
          form.append(i, state[i]);
        });

        await axios.post(apiRoutes.FEEDBACK, form, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        setError({});
        setState(initState);
        setLoading(false);
        router.push('/thank-you?target=contact');
      } catch (error) {
        setLoading(false);
        setTimeout(() => alert('Something went wrong. please try again.'), 100);
      }
    } else {
      // setTimeout(() => alert('Some fields are missing.'), 1000);
    }
  };

  return (
    <div className={styles.contact}>
      <div className={`${styles.info} box-fade-in`}>
        <div className={styles.about}>
          <div className={styles.img}>
            <Image src={LogoBlue} />
          </div>
          <div dangerouslySetInnerHTML={{ __html: data?.subheadline }} />
        </div>
        <div className={styles.phone}>
          <div dangerouslySetInnerHTML={{ __html: data?.content }} />
        </div>
        <ul className={styles.links}>
          {menuItems?.map((el) => {
            return (
              <li style={el?.url === router.pathname ? { opacity: '0.5', pointerEvents: 'none' } : {}} key={el?.id}>
                <Link href={el?.url ?? '/'}>{el?.label}</Link>
              </li>
            );
          })}
        </ul>
      </div>
      <div className={`${styles.form} box-fade-in`}>
        <h2 className={styles.title}>Contact us</h2>
        <div className="row mb-s">
          <div className="col">
            <Input
              onChange={handleChange}
              name="first-name"
              className="form-first-name"
              error={error}
              value={state['first-name']}
              label="First Name"
            />
          </div>
          <div className="col">
            <Input
              onChange={handleChange}
              name="last-name"
              className="form-last-name"
              error={error}
              value={state['last-name']}
              label="Last Name"
            />
          </div>
        </div>
        <div className="row mb-s">
          <div className="col">
            <Input
              className="form-phone"
              onChange={handleChange}
              name="phone"
              error={error}
              value={state.phone}
              label="Phone"
            />
          </div>
          <div className="col">
            <Input
              className="form-email"
              onChange={handleChange}
              name="email"
              error={error}
              value={state.email}
              label="E-mail"
            />
          </div>
        </div>
        <Dropdown
          className="form-department mb-s"
          onChange={handleChange}
          name="department"
          error={error}
          value={state.department}
          options={[
            { label: 'Service', value: 'Service' },
            { label: 'Sales', value: 'Sales' },
            { label: 'Feedback', value: 'Feedback' },
            { label: 'Complain', value: 'Complain' },
            { label: 'Offer', value: 'Offer' },
          ]}
          label="Department"
        />
        <Input
          className="form-message mb-s"
          onChange={handleChange}
          name="message"
          error={error}
          value={state.message}
          type="textarea"
          label="Message"
        />
        <Button disabled={loading} onClick={validateFields} type="primary">
          Send
        </Button>
      </div>
    </div>
  );
};

export default Contact;
