import React, { FC } from 'react';

import styles from './Input.module.scss';

interface InputProps {
  type?: 'input' | 'textarea';
  label?: string;
  className?: string;
  htmlType?: string;
  value?: string;
  onChange?: any;
  name?: string;
  error?: any;
  placeholder?: string;
}

const Input: FC<InputProps> = ({
  type = 'input',
  label,
  name,
  className,
  htmlType = 'text',
  value = '',
  onChange,
  error = {},
  placeholder,
}) => {
  return (
    <div className={className}>
      <label className={styles.label} htmlFor="">
        {label}
      </label>
      {type === 'input' ? (
        <input
          tabIndex={0}
          placeholder={placeholder}
          value={value}
          required
          name={name}
          onChange={onChange}
          className={styles.input}
          type={htmlType}
        />
      ) : (
        <textarea
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className={styles.textarea}
          name={name}
          id=""
          rows={5}
        />
      )}
      {error && error[name ?? ''] && <div className={styles.error}>{error[name ?? '']}</div>}
    </div>
  );
};

export default Input;
