import { SeoContentType } from 'models/types.interface';
import Head from 'next/head';
import { FC } from 'react';
import metadata from '../../config/metadata';

interface SEOProps {
  data: SeoContentType;
}

const SEO: FC<SEOProps> = ({ data }) => {
  const {
    originalTitle,
    originalDescription,
    siteName,
    social: { twitter },
    defaultURL,
    originalImage,
  } = metadata;
  return (
    <Head>
      <title>{`${data?.title ? data?.title : originalTitle}`}</title>
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#0c2340" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#0C2340" />
      <meta name="description" content={`${data?.metaDesc ? data?.metaDesc : originalDescription}`} />
      <meta
        name="image"
        content={`${data?.opengraphImage.sourceUrl ? data?.opengraphImage.sourceUrl : originalImage}`}
        key="ogtitle"
      />
      {/*{article ? (*/}
      {/*  <meta property="og:type" content="article" key="ogtype" />*/}
      {/*) : (*/}
      {/*  <meta property="og:type" content="website" key="ogtype" />*/}
      {/*)}*/}
      <meta property="og:type" content="website" key="ogtype" />
      <meta
        property="og:title"
        content={`${data?.opengraphTitle ? data?.opengraphTitle : originalTitle}`}
        key="ogtitle"
      />
      <meta
        property="og:description"
        content={`${data?.opengraphDescription ?? data?.metaDesc ?? originalDescription}`}
        key="ogdesc"
      />
      <meta property="twitter:card" content="summary_large_image" key="twcard" />
      <meta name="twitter:creator" content={twitter} key="twhandle" />
      <meta
        name="twitter:title"
        content={`${data?.twitterTitle ?? data?.opengraphTitle ?? originalTitle}`}
        key="twtitle"
      />
      <meta
        name="twitter:description"
        content={`${data?.twitterDescription ?? data?.opengraphDescription ?? data?.metaDesc}`}
        key="twdescription"
      />
      <meta
        name="twitter:image"
        content={`${data?.twitterImage?.sourceUrl ?? data?.opengraphImage?.sourceUrl}`}
        key="twimage"
      />
      <meta property="og:url" content={`${data?.opengraphUrl ?? defaultURL}`} key="ogurl" />
      <meta property="og:image" content={`${data?.opengraphImage.sourceUrl ?? originalImage}`} key="ogimage" />
      <meta property="og:site_name" content={data?.opengraphSiteName ?? siteName} key="ogsitename" />
      <meta name="google-site-verification" content="8InoVhvp03rDyel7LKGcU5H-q6z4oQUqF2Jksq4PWio" />
    </Head>
  );
};

export default SEO;
