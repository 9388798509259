/* eslint-disable react/jsx-no-target-blank */
import React, { FC } from 'react';

import styles from './Footer.module.scss';

import { FaFacebookF, FaInstagram, FaYoutube, FaLinkedinIn, FaTwitter, FaTiktok } from 'react-icons/fa';
import { SocialsContentType } from 'models/types.interface';

interface FooterProps {
  data: SocialsContentType[];
  title: string;
}

const Footer: FC<FooterProps> = ({ data, title }) => {
  const getIcon = (name: any) => {
    if (name === 'facebook') {
      return <FaFacebookF />;
    } else if (name === 'instagram') {
      return <FaInstagram />;
    } else if (name === 'youtube') {
      return <FaYoutube />;
    } else if (name === 'linkedin') {
      return <FaLinkedinIn />;
    } else if (name === 'twitter') {
      return <FaTwitter />;
    } else if (name === 'tiktok') {
      return <FaTiktok />;
    }
    return null;
  };
  return (
    <footer className={styles.footer}>
      <p className={styles.text}>
        © Copyright {new Date().getFullYear()} - {title ?? 'Willis Custom Yachts'} - All rights reserved.
      </p>

      <ul className={styles.socials}>
        {data?.map((el, i) => {
          return (
            <a key={i} target="_blank" href={el?.social_media?.url}>
              <li>{getIcon(el?.social_media?.icon)}</li>
            </a>
          );
        })}
      </ul>
    </footer>
  );
};

export default Footer;
