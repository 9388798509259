import gsap from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';

export default class LineFadeIn {
  paragraph: Element;
  splitText: SplitText;
  splitTextMain: SplitText;

  constructor(el: Element) {
    this.paragraph = el;
    this.splitTextMain = new SplitText(el, {
      type: 'lines',
      linesClass: 'st-child',
    });
    this.splitText = new SplitText(el, {
      type: 'lines',
      linesClass: 'hidden',
    });

    gsap
      .from(el.querySelectorAll('.st-child'), {
        y: 200,
        duration: 1,
        ease: 'expo.out',
        stagger: 0.15,
        scrollTrigger: {
          trigger: el,
        },
      })
      .then(() => {
        this.splitText.revert();
        this.splitTextMain.revert();
      });
  }
}
