import { ContentDataType, MenuItemDataType } from 'models/types.interface';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FC, useEffect } from 'react';

import styles from './Drawer.module.scss';

interface DrawerProps {
  open: boolean;
  toggleOpen: any;
  menuItems: MenuItemDataType[];
  footer: ContentDataType;
}

const Drawer: FC<DrawerProps> = ({ open, toggleOpen, menuItems, footer }) => {
  const router = useRouter();
  useEffect(() => {
    const userInfo = document.querySelector('.userInfo');
    const phone = userInfo?.querySelector('h3');
    const address = userInfo?.querySelector('p');

    const phoneLink = document.createElement('a');
    phoneLink.href = `tel:${phone?.innerText}` ?? '';
    if (phone) {
      phoneLink?.append(phone);
    }

    const addressLink = document.createElement('a');
    addressLink.href = 'https://www.google.com/maps/place/6800%20SW%20Jack%20James%20Drive,%20Stuart,%20FL%2034997';
    if (address) {
      addressLink.target = '_blank';
      addressLink?.append(address);
    }

    userInfo?.appendChild(phoneLink);
    userInfo?.appendChild(addressLink);
  }, []);

  return (
    <div className={`${styles.drawer} ${open ? styles.open : styles.close}`} onClick={toggleOpen}>
      <div onClick={(e) => e.stopPropagation()} className={`${styles.main} drawer`}>
        <ul className={styles.list}>
          {menuItems?.map((el) => {
            return (
              <li key={el?.id}>
                <div style={el?.url === router.pathname ? { opacity: '0.5', pointerEvents: 'none' } : {}}>
                  <Link href={el?.url ?? '/'}>{el?.label}</Link>
                </div>
              </li>
            );
          })}
        </ul>
        <div className={styles.info}>
          <div className="userInfo" dangerouslySetInnerHTML={{ __html: footer?.content }}></div>
          <div dangerouslySetInnerHTML={{ __html: footer?.subheadline }}></div>
        </div>
      </div>
    </div>
  );
};

export default Drawer;
