import environment from './environment';

const BASE_URL = `${environment.URL}`;

const apiRoutes = {
  JOB_POSTING: `${BASE_URL}/wp-json/job-applications/store`,
  PDF_UPLOAD: `${BASE_URL}/wp-json/job-applications/upload`,
  FEEDBACK: `${BASE_URL}/wp-json/contact-form-7/v1/contact-forms/294/feedback`,
};

export default apiRoutes;
