import React, { FC } from 'react';
import Image from 'next/image';

import { MediaDataType, BulletListContentType } from 'models/types.interface';

import styles from './ImageList.module.scss';
import VillBlue from '../../public/images/anchor-blue.svg';

interface ImageListProps {
  image?: MediaDataType;
  list?: BulletListContentType[];
}

const ImageList: FC<ImageListProps> = ({ image, list }) => {
  return (
    <div className={styles.imageList}>
      <div className={`${styles.img} img-scale-up`}>
        <img src={image?.sourceUrl} alt={image?.altText} />
      </div>
      <ul data-delay={1} className="list-fade-in">
        {list?.map((el, i) => (
          <li key={i}>
            <div className={styles.logo}>
              <Image src={VillBlue} />
            </div>
            <div className={styles.text} dangerouslySetInnerHTML={{ __html: el?.item ?? '' }}></div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ImageList;
