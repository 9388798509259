import React, { FC } from 'react';

import { BulletListContentType } from 'models/types.interface';

import anchorWhite from '../../public/images/anchor-white.svg';
import styles from './BulletList.module.scss';
import Image from 'next/image';

interface BulletListProps {
  data: BulletListContentType[];
  noMarginBottom?: boolean;
}

const BulletList: FC<BulletListProps> = ({ data, noMarginBottom }) => {
  return (
    <div className={`${styles.bulletList} ${noMarginBottom ? `${styles?.noMargin}` : ''}`}>
      <ul className="list-fade-in">
        {data?.map((el, i) => (
          <li key={i}>
            <div className={styles.logo}>
              <Image src={anchorWhite} />
            </div>
            <span>{el?.item}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BulletList;
