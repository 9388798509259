import gsap from 'gsap';

export default class BoxFadeIn {
  el: NodeListOf<Element>;
  constructor(el: NodeListOf<Element>) {
    this.el = el;

    el.forEach((item, i) => {
      gsap.from(item, {
        y: 100,
        duration: 1,
        ease: 'expo.out',
        opacity: 0,
        // delay: i * 0.2 + 0.5,
        scrollTrigger: {
          trigger: item,
        },
      });
    });
  }
}
